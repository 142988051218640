import React, {Component} from 'react'
import image_self_love from './images/blogging.jpg';
import image_coding from './images/coding.jpg';
import image_astronomy from './images/astronomy.jpg';
import image_welcome_self from './images/Kuhuk_photo.png';
import github_image from './images/github.svg';
import facebook_image from './images/facebook.svg';
import linkedin_image from './images/linkedin.svg';
import instagram_image from './images/instagram.svg';
import twitter_image from './images/twitter.svg';

class Kuhuk extends Component {
    render() {
        return(
            <div>
                <div className="div-welcome">
                    <p className="div-welcome-text welcome-text">Hi there! Welcome to my place on the internet.<br/><br/>I am Kuhuk Sharma. I am a software engineer and I have 6 years of professional experience in Android applications development. I love playing around with technologies other than my mainstream work and a result of that is this website that I created & deployed with a generous amount of passion and love.<br/><br/>I created this website for showcasing my portfolio and to learn web development. This is the first full-fledged project I took up and architected it. I ideated, designed, developed, and deployed this project just with the help of the internet. I was the product manager, the designer, the developer, the architect, the tester, and not least - the first user 🖤</p>
                    <div className="div-welcome-image">
                        <img class="welcome-image" src={image_welcome_self}/>
                    </div>
                </div>
                <div className="div-home">
                    <div className="div-what-i-do">
                        <p className="text-body-center">activities I love doing</p>
                    </div>
                    <div className="distribute_horizontally">
                        <a href="/coding" className="a-hover">
                            <div className="div-hover">
                                <div className="outer_circle">
                                    <div className="inner_circle_second">
                                        <img src={image_coding} class="circle_image"/>
                                    </div>
                                </div>
                                <p className="text-middle-cta">coding</p>
                            </div>
                        </a>
                        <a href="/astronomy" className="a-hover">
                            <div className="div-hover">
                                <div className="outer_circle">
                                    <div className="inner_circle_second">
                                        <img src={image_astronomy} class="circle_image"/>
                                    </div>
                                </div>
                                <p className="text-middle-cta">stargazing</p>
                            </div>
                        </a>
                        <a href="/blog" className="a-hover">
                            <div className="div-hover">
                                <div className="outer_circle">
                                    <div className="inner_circle_second">
                                        <img src={image_self_love} class="circle_image"/>
                                    </div>
                                </div>
                                <p className="text-middle-cta">writing</p>
                            </div>
                        </a>
                    </div>
                    <div className="div-connect-with-me">
                        <p className="text-body-center">connect on my socials</p>
                    </div>
                    <div className="div-social-media-image">
                        <a href="https://www.github.com/kuhuk" target="_blank">
                            <img className="social-media-image" alt="GitHub" src={github_image} width="48" height="48"/>
                        </a>
                        <a href="https://www.instagram.com/astro_kuhuk/?hl=en" target="_blank">
                            <img className="social-media-image" alt="Instagram" src={instagram_image} width="48" height="48"/>
                        </a>
                        <a href="https://www.linkedin.com/in/kuhuk/" target="_blank">
                            <img className="social-media-image" alt="LinkedIn" src={linkedin_image} width="48" height="48"/>
                        </a>
                        <a href="https://www.facebook.com/sharma.kuhuk/" target="_blank">
                            <img className="social-media-image" alt="Facebook" src={facebook_image} width="48" height="48"/>
                        </a>
                        <a href="https://twitter.com/astro_kuhuk/" target="_blank">
                            <img className="social-media-image" alt="Twitter" src={twitter_image} width="48" height="48"/>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Kuhuk